import api from "../httpClient.js";

export default {
  async getEventosPainel() {
    const response = await api.get("/eventospainel");
    return response.data;
  },

  async getMeusAtendimentos(id) {
    const response = await api.get(`/usuarios/${id}/eventos`);
    return response.data;
  }
};
